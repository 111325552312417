import React, { Fragment, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  VStack,
  Alert,
  AlertTitle,
  AlertDescription,
  Button,
} from '@chakra-ui/react';
import { FaSyncAlt } from 'react-icons/fa';

export const FormInput = ({
  labelText,
  type = 'text',
  onChange,
  onBlur,
  name,
  required,
  placeholder,
  validationErrors,
  isInvalid,
  isLoading, // eslint-disable-line no-unused-vars
  submitForm,
  renderInputElement = ({
    type: inputType,
    onChange: inputOnChange,
    onBlur: inputOnBlur,
    name: inputName,
    required: inputRequired,
    placeholder: inputPlaceholder,
  }) => (
    <Input
      variant="outline"
      bg="white"
      type={inputType}
      onChange={inputOnChange}
      onBlur={inputOnBlur}
      name={inputName}
      required={inputRequired}
      placeholder={inputPlaceholder}
    />
  ),
}) => (
  <FormControl
    id={`capture-details-${name}`}
    isRequired={required}
    isInvalid={isInvalid}
    mb={2}
  >
    <Flex>
      <FormLabel textAlign="right" flexBasis="150px" mt={2} mb={0}>
        {labelText}
      </FormLabel>
      <Flex flexDir="column" flexGrow="1">
        {renderInputElement({
          type,
          onChange,
          onBlur,
          submitForm,
          name,
          required,
          placeholder,
        })}
        {isInvalid && (
          <FormHelperText mb={2}>
            <VStack align="flex-start">
              {validationErrors.map((error) => (
                <Text color="red" key={error}>
                  {error}
                </Text>
              ))}
            </VStack>
          </FormHelperText>
        )}
      </Flex>
    </Flex>
  </FormControl>
);

const PERSONAL_EMAIL_REGEX = new RegExp(
  /.*@(gmail|googlemail|yahoo|hotmail|outlook|live)\..*/,
  'i',
);

const hasFilledInFields = (formData) =>
  Object.values(formData).filter(
    (data) => typeof data === 'string' && data.length > 0,
  ).length > 0;

const hasValidationErrors = (validationErrors) =>
  typeof validationErrors !== 'undefined' &&
  Object.values(validationErrors).filter(
    (errors) => typeof errors !== 'undefined' && errors.length > 0,
  ).length > 0;

const updateState = ({
  event,
  fields,
  formData,
  shouldPerformValidation,
  validate,
  setFormData,
  setValidationErrors,
  validationErrors: currentValidationErrors,
}) => {
  const attributeName = event.target.getAttribute('name');
  const { value } = event.target;
  const newFormData = {
    ...formData,
    [attributeName]: value,
  };
  setFormData(newFormData);
  if (shouldPerformValidation) {
    const validationErrors = validate({ formData: newFormData, fields });
    if (!hasFilledInFields(formData)) {
      setValidationErrors({});
    } else {
      setValidationErrors({
        ...currentValidationErrors,
        [attributeName]: validationErrors
          ? validationErrors[attributeName]
          : [],
      });
    }
  }
};

const onSubmit = async ({
  fields,
  formData,
  handleSubmit,
  validate,
  setValidationErrors,
  isLoading,
  setIsLoading,
  setSuccessText,
  setErrorText,
}) => {
  const validationErrors = validate({ formData, fields });
  if (hasValidationErrors(validationErrors)) {
    setValidationErrors(validationErrors);
    return false;
  }
  if (isLoading) {
    return false;
  }
  if (typeof handleSubmit !== 'function') {
    return false;
  }
  setIsLoading(true);
  try {
    const result = await handleSubmit(formData);
    setSuccessText(result);
    setErrorText(false);
    return true;
  } catch (e) {
    const errorMessage =
      e.message.indexOf('@timelesstime.co.uk') === -1
        ? `The following error occured \`${e.message}\`. Please email us at info@timelesstime.co.uk.`
        : e.message;
    setErrorText(errorMessage);
    setSuccessText(false);
    return false;
  } finally {
    setIsLoading(false);
  }
};

const validateFormData = ({ formData, fields }) =>
  fields.reduce((carry, field) => {
    const { validate, name } = field;
    if (typeof validate === 'function') {
      const fieldValue = formData[name];
      return {
        ...carry,
        [name]: validate(fieldValue),
      };
    }
    return carry;
  }, {});

export const SubmitButton = ({
  text = 'Submit',
  submitForm,
  isLoading,
  isValid,
  children,
}) => (
  <Flex mt={8} mb={2} justify="center">
    <Button
      colorScheme="orange"
      onClick={submitForm}
      disabled={isLoading || !isValid}
      isLoading={isLoading}
      loadingText="Loading"
      spinner={<FaSyncAlt />}
    >
      {text}
    </Button>
    {children}
  </Flex>
);

const CaptureDetailsForm = ({
  fields = [
    // {
    //     label,
    //     text,
    //     name,
    //     required,
    //     placeholder,
    //     inputType
    //     validate
    //     createFormInput
    // }
  ],
  handleSubmit = (formData) => undefined, // eslint-disable-line no-unused-vars
  renderSubmitButton = (props) => <SubmitButton {...props} />,
  showPersonalEmailWarning = true,
  ...props
}) => {
  const [formData, setFormData] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [successText, setSuccessText] = useState(false);
  const [errorText, setErrorText] = useState('');
  const hasPersonalisedEmail =
    formData.email && formData.email.match(PERSONAL_EMAIL_REGEX);

  const submitForm = () =>
    onSubmit({
      fields,
      formData,
      handleSubmit,
      validate: validateFormData,
      setValidationErrors,
      isLoading,
      setIsLoading,
      setSuccessText,
      setErrorText,
    });

  if (successText) {
    return <Box>{successText}</Box>;
  }

  return (
    <Box as="form" {...props}>
      {fields.map((fieldConfig) => {
        const createFormInput =
          fieldConfig.createFormInput ||
          ((createFormInputProps) => <FormInput {...createFormInputProps} />);
        const validationErrorsForField =
          validationErrors[fieldConfig.name] || [];
        return (
          <Fragment key={fieldConfig.name}>
            {createFormInput({
              validationErrors:
                typeof validationErrorsForField === 'object'
                  ? validationErrorsForField
                  : [validationErrorsForField],
              isInvalid:
                typeof validationErrorsForField !== 'undefined' &&
                validationErrorsForField.length >= 1,
              isLoading,
              labelText: fieldConfig.label,
              onBlur: (event) =>
                updateState({
                  event,
                  fields,
                  formData,
                  validate: validateFormData,
                  shouldPerformValidation: true,
                  setFormData,
                  setValidationErrors,
                  validationErrors,
                }),
              onChange: (event) =>
                updateState({
                  event,
                  fields,
                  formData,
                  validate: validateFormData,
                  shouldPerformValidation: false,
                  setFormData,
                  setValidationErrors,
                  validationErrors,
                }),
              submitForm,
              name: fieldConfig.name,
              required: fieldConfig.required,
              placeholder: fieldConfig.placeholder,
            })}
          </Fragment>
        );
      })}

      {showPersonalEmailWarning && hasPersonalisedEmail && (
        <Alert
          status="warning"
          variant="left-accent"
          flexDir="column"
          alignItems="flex-start"
          mt={4}
          mb={4}
        >
          <VStack spacing={2}>
            <AlertTitle>
              TimelessTime provides support to businesses but you seem to have
              used a personal email address.
            </AlertTitle>
            <AlertDescription>
              Since our contract is with your firm we’d rather communicate via
              your work email. If you don’t have one we’ll still get back to
              you, but if it’s a personal question we may not be able to help.
            </AlertDescription>
          </VStack>
        </Alert>
      )}

      {errorText && errorText.length > 0 && (
        <Alert
          status="error"
          variant="left-accent"
          flexDir="column"
          alignItems="flex-start"
          mt={4}
          mb={4}
        >
          <AlertTitle>Error submitting form</AlertTitle>
          <AlertDescription>{errorText}</AlertDescription>
        </Alert>
      )}

      {typeof renderSubmitButton === 'function' &&
        renderSubmitButton({
          submitForm,
          isLoading,
          isValid: !hasValidationErrors(validationErrors),
        })}
    </Box>
  );
};

export default CaptureDetailsForm;
